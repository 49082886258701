@import "../mixins";
@import "../variables";

.tab {
    display: inline-block;
    padding: 6px 7px;
    background-color: whitesmoke;
    color: #212529;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid transparent;
    border-top-left-radius: 6px;
    border-top-right-radius: 13px;
    transition: background-color 0.3s;
    margin-top: 11px;
    font-size: 14px;
  }
  
  .tab:hover {
    background-color: #bebdbd;
  }
  
  .tab.active {
    border-bottom-color: #212529;
    border-width: medium;
    background: whitesmoke;
    margin: 5px;
  }

  .breadcrumb {
    float: left;
    margin-top: 18px;
    margin-left: 8px;
    color: #212529;
  }

  .breadcrumb:hover {
    cursor: pointer;
    color: #bebdbd;
  }

  .logout {
    float: right;
    margin-top: 16px;
    margin-right: 8px;
    color: #212529;
    font-weight: normal;
    font-size: 16px;
    font-weight: 700;
  }

  .logout:hover {
    cursor: pointer;
    color: #bebdbd;
  }

  .tabs {
    //margin-left: calc( (100vw - 1600px) / 2)
    margin-left: 20px;
  }
  .auxtabframe {
    border: 0;
    //margin-left: calc( (100vw - 1600px) / 2);
    width: 0;
    height: 0;
    background-repeat: no-repeat, repeat;
    background-position: center;
    display:none;
    background-color: #eaebf0;
  }

  .tabframe {
    border: 0;
    //margin-left: calc( (100vw - 1600px) / 2);
    //width: calc( (100vw - 40px));
    height: 100vh;
    background-repeat: no-repeat, repeat;
    background-position: center;
    display:none;
    background-color: $back-color;
  }

  .icon {
    float: right;
    margin-top: 11px;
    margin-right: calc((101vw - 1600px) / 2);
    margin-right: 10px;
    cursor: pointer;
    height: 26px;
  }

  .pdficon {
    float: right;
    margin-top: 8px;
    cursor: pointer;
    height: 29px;
  }

  .pwrpicon {
    float: right;
    margin-top: 10px;
    cursor: pointer;
    height: 25px;
    margin-right: 8px;
  }

  .excelicon {
    float: right;
    margin-top: 8px;
    cursor: pointer;
    height: 29px;
    margin-right: 10px;
  }

  .gsicon {
    float: left;
    margin-right: 0;
    margin-left: 17px;
    margin-bottom: 10px;
    margin-top: 17px;
    cursor: pointer;
    height: 65px;
  }

  .dividericon {
    float: right;
    margin-top: 7px;
    margin-right: 21px;
    height: 31px;
  }

  .toolbar {
    width: 100%;
    fill: black;
    background-color: #f5f5f5;
    display: inline-block;
    margin-bottom: -3px;
    height: 40px;
  }

  .contentblock {
    //margin-left: 300px;
    //calc( (100vw - 300px));
    //display: block;
    margin-left: 170px;
    display: block;
    height: 100%;
    width: calc( (100vw - 170px));
    display: inline-block;
    position: fixed !important;
    z-index: 1;
  }

  .dashboardblock {
    width: 100%;
    fill: black;
    display: inline-block;
    height: 100%;
    background-color: $back-color;

  }

  .sidebar {
    width: 170px;
    height: 100%;
    fill: black;
    border-bottom-color: $sky-theme;
    border-width: medium;
    border-bottom-style: solid;
    background-color: $slate-theme;
    display: block;
    position: fixed!important;
    z-index: 1;
    overflow: auto;
  }

  .sidebarlogo {
    margin-top: 10px;
    margin-left: 28px;
    text-align: center;
    align-items: center;
    width: 120px;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
  }

  .sidebarState {
    margin-top: 10px;
    margin-left: 10px;
    text-align: center;
    align-items: center;
    width: 150px;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    margin-top: 20px;
  }

  .sideHeader {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid white;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
  }

  .sidePortalName{
    text-align: center;
    position: absolute;
    bottom: 0;
    margin-bottom: 35px;
    font-size: 23px;
  }

  .sideState {
    height: 50px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    margin-bottom:40px;
    font-size: 14px;
    font-weight: normal;
  }

  .sideButton {
    height: 50px;
    width: 100%;
    border-color: white;
    border-width: medium;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    font-weight: normal;
  }

  .sideButton.active {
    background-color: #848484;
    color: $line-theme;
  }

  .sideButton.active:hover {
    color: #f5f5f5;
  }

  .sideButton:hover {
    cursor: pointer;
    color: #f5f5f5;
  }

  .tabbar {
    width: 100%;
    fill: black;
    background-color: $back-color;
  }

  .landing {
    overflow: scroll;
    //border-bottom-color: $line-theme;
    /* border-bottom-color: black; */
    //border-width: medium;
    //border-bottom-style: solid;
    //display: inline-block;
    width: 100%;
  }

  .loadingSpinnerContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    z-index: 9999;
    top:0
  }
  
  /* Styles for the spinning wheel */
  .loadingSpinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #ffffff; /* Adjust the color as needed */
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: fixed;
  }
  
  /* Animation for spinning */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media all and (max-width: 1600px) {
    .tabframe {
        border: 0;
        background-color: $back-color;
        margin-left: 0;
        width: 100%;
        height: 100vh;
        background-repeat: no-repeat, repeat;
        background-position: center;
      }

      .tabs {
        margin-left: 0
      }

      .icon {
        margin-right: 10px;
      }
 }